html,
body {
  /* width: 100%; */
  height: 100%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

/*********** Module Spacer ***********/
.module-spacer--extra-extra-small {
  height: 20px;
}
.module-spacer--extra-small {
  height: 12px;
}
.module-spacer--small {
  height: 20px;
}
.module-spacer--medium {
  height: 32px;
}

/*********** Section ***********/
.c-main {
  padding: 50px 0;
}
.c-section-wrapin {
  margin: 0 auto;
  max-width: 575px;
  position: relative;
  padding: 0 1rem;
  text-align: center;
  width: 100%;
}

.c-section-container {
  margin: 0 auto;
  max-width: 390px;
  padding: 1rem;
  height: auto;
  width: calc(100% - 2rem);
}

.c-section__loading {
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-flow: column;
  justify-content: center;
  opacity: 0.9;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 9999;
}

.c-section__loading > p {
  color: #444;
  font-weight: 600;
  margin-top: 1rem;
  text-align: center;
}

.center {
  margin: 0 auto;
  text-align: center;
  padding: 0 5px;
  /* padding: 1rem; */
}

.p-grid__column {
  align-items: center;
  display: flex;
  flex-flow: column;
}

.p-grid__row {
  display: flex;
  flex-flow: row wrap;
}

.p-grid__list-images {
  /* display: flex; */
  flex-flow: wrap;
  width: 100%;
}

.p-grid__list-images > .p-media__thumb {
  margin: 0.5rem;
  width: calc(50% - 1rem);
}

.p-media__thumb {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.p-media__thumb::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.p-media__thumb > img {
  position: absolute;
  object-fit: contain;
  object-position: center;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

/*********** Footer ***********/

.footer_desc {
  font-weight: bold;
  margin: auto;
}
@media screen and (max-width: 600px) {
  .footer_desc {
    font-size: 8px;
  }
}
@media screen and (min-width: 601px) and(max-width:960px) {
  .footer_desc {
    font-size: 10px;
  }
}
@media screen and (min-width: 961px) {
  .footer_desc {
    font-size: 12px;
  }
}

.footer_box {
  position: relative;
}

.footer_box_p {
  position: absolute;
  top: 60%;
  left: 30%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: aliceblue;
}

.footer_box_p1 {
  position: absolute;
  top: 60%;
  left: 62%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: aliceblue;
}
.footer_box_p2 {
  position: absolute;
  top: 90%;
  left: 80%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: aliceblue;
  font-size: 0.1rem;
}

.l-footer {
  display: initial;
  flex-flow: row wrap;
  list-style: none;
  margin: auto;
  text-align: left;
  position: absolute;
}

.footer_box a {
  color: #fff;
  font-size: 0.5rem;
}

.u-text-small {
  font-size: 0.9rem;
}

.u-text__link-none {
  text-decoration: none;
  color: inherit;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-text-center {
  text-align: center;
}

.u-text__headline {
  color: #ff7c21;
  font-size: 1.563rem;
  margin: 0 auto 1rem auto;
}

.u-text__headline_liver {
  color: #26cd18;
  font-size: 1.563rem;
  margin: 0 auto 1rem auto;
}

.u-display-none {
  display: none;
}

.footer_style {
  max-width: 900px;
  margin: auto;
}

@media screen and (max-width: 600px) {
  .footer_img {
    width: 100%;
    height: 200px;
  }
}

@media screen and (min-width: 600px) {
  .footer_img {
    width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 576px) {
  /*********** Module Spacer ***********/
  .module-spacer--extra-extra-small {
    height: 16px;
  }
  .module-spacer--extra-small {
    height: 20px;
  }
  .module-spacer--small {
    height: 32px;
  }

  /*********** Section ***********/
  .c-section-wrapin {
    max-width: 1024px;
  }
}

@media screen and (min-width: 1024px) {
  /*********** Module Spacer ***********/
  .module-spacer--medium {
    height: 48px;
  }
}
.samplebox {
  position: relative;
}
.imagebox {
  height: 200;
  width: 300;
}

.sampledesc {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  color: grey;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  /*　画面の中央に要素を表示させる設定　*/
  display: flex;
  align-items: center;
  justify-content: center;
}

.balloon4 {
  position: relative;
  margin: 1em 1rem 2em 40px;
  padding: 15px;
  background: #fff;
  border-radius: 50%;
}

.balloon4:before {
  content: "";
  position: absolute;
  left: -38px;
  width: 13px;
  height: 12px;
  bottom: 0;
  background: #fff;
  border-radius: 50%;
}

.balloon4:after {
  content: "";
  position: absolute;
  left: -24px;
  width: 20px;
  height: 18px;
  bottom: 3px;
  background: #fff;
  border-radius: 50%;
}
.balloon4 p {
  margin-top: 1rem;
  padding: 0;
  color: #808080;
}

/* header */
.header_root {
  flex-grow: 0;
  max-width: 1000px;
  margin: auto;
  background-color: #fff;
}

.header_menuBar {
  background-color: #fff;
  color: #444;
  width: 100%;
}

.header_toolBar {
  margin: 0px auto;
  width: 100%;
  padding: 0px;
}

.header_iconButton {
  margin: 0px 0px 0px auto;
  display: block;
}

.header_iconImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

/* page */

.page_username {
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  .page_root {
    margin: 14px 5px;
    width: calc(50% - 16px);
  }
  .page_icon {
    width: 100px !important;
    height: 100px !important;
    margin: auto !important;
  }

  .page_size {
    width: 130px;
    height: 130px;
  }

  .icon_size {
    width: 50px;
    height: 50px;
  }

  .page_username {
    font-size: 18px;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {
  .page_size {
    width: 180px;
    height: 180px;
  }

  .page_icon {
    width: 150px !important;
    height: 150px !important;
    margin: auto !important;
  }

  .icon_size {
    width: 80px;
    height: 80px;
  }

  .page_root {
    margin: 15px 10px;
  }

  .page_username {
    font-size: 25px;
  }
}

@media screen and (min-width: 961px) {
  .page_size {
    width: 220px;
    height: 220px;
  }

  .page_icon {
    width: 150px !important;
    height: 150px !important;
    margin: auto !important;
  }

  .page_root {
    margin: 25px 16px;
    width: calc(33.3333% - 32px);
  }
  .page_username {
    font-size: 30px;
  }
}

@media screen and (min-width: 601px) {
  .icon_size {
    width: 120px;
    height: 120px;
  }
}

/* art_detail */

.detail_box {
  margin: auto;
  position: relative;
  object-fit: contain;
}
.detail_collecter_alert {
  position: absolute !important;
  width: 30px;
  height: 30px;
  bottom: 0% !important;
  right: 0% !important;
  z-index: 1;
}
.detail_square {
  margin: 0 auto;
}
.dialog_detail_box {
  margin: auto;
  position: relative;
  object-fit: contain;
}
.buy_button {
  background-color: #ff7c21 !important;
  margin-top: 20px !important;
}
/* sm */
@media screen and (max-width: 600px) {
  .detail_box {
    /* width: 350px; */
    width: 100%;
  }
  .detail_alert_width {
    width: 100%;
    text-align: right;
  }
  .detail_square {
    width: 100%;
    object-fit: contain;
    pointer-events: none;
  }
  .detail_watermark1 {
    position: absolute;
    top: 10%;
    left: 10%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark2 {
    position: absolute;
    top: 20%;
    left: 20%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark3 {
    position: absolute;
    top: 30%;
    left: 30%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark4 {
    position: absolute;
    top: 40%;
    left: 40%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark5 {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark6 {
    position: absolute;
    top: 60%;
    left: 60%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark7 {
    position: absolute;
    top: 70%;
    left: 70%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark8 {
    position: absolute;
    top: 80%;
    left: 80%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark9 {
    position: absolute;
    top: 90%;
    left: 90%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark10 {
    position: absolute;
    top: 0%;
    left: 0%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .dialog_detail_box {
    width: 300px;
    height: 300px;
  }
  .dialog_detail_square {
    width: 100%;
    object-fit: contain;
    pointer-events: none;
  }
  .dialog_detail_height {
    margin: auto;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    pointer-events: none;
  }
  .dialog_detail_width {
    margin: auto;
    width: 100%;
    pointer-events: none;
  }
  .detail_height {
    margin: 0 auto;
    width: 100%;
    pointer-events: none;
  }
  .detail_width {
    margin: 0 auto;
    width: 100%;
    pointer-events: none;
  }
  .art_detail_title {
    font-size: 24px;
    /* margin-top: 0px; */
    margin-left: 20px;
  }
}
/* md */
@media screen and (min-width: 601px) and (max-width: 960px) {
  .detail_box {
    width: 400px;
    height: 400px;
  }
  .detail_alert_width {
    width: 400px;
    text-align: right;
  }
  .detail_square {
    width: 400px;
    height: 400px;
    object-fit: contain;
    pointer-events: none;
  }
  .detail_watermark1 {
    position: absolute;
    top: 10%;
    left: 10%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark2 {
    position: absolute;
    top: 20%;
    left: 20%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark3 {
    position: absolute;
    top: 30%;
    left: 30%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark4 {
    position: absolute;
    top: 40%;
    left: 40%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark5 {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark6 {
    position: absolute;
    top: 60%;
    left: 60%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark7 {
    position: absolute;
    top: 70%;
    left: 70%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark8 {
    position: absolute;
    top: 80%;
    left: 80%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark9 {
    position: absolute;
    top: 90%;
    left: 90%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark10 {
    position: absolute;
    top: 0%;
    left: 0%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .dialog_detail_box {
    width: 600px;
    height: 600px;
  }
  .dialog_detail_square {
    width: 600px;
    height: 600px;
    object-fit: contain;
    pointer-events: none;
  }
  .dialog_detail_height {
    margin: 0 auto;
    width: auto;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    pointer-events: none;
  }
  .dialog_detail_width {
    margin: 0 auto;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    pointer-events: none;
  }
  .detail_height {
    margin: 0 auto;
    width: auto;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    pointer-events: none;
  }
  .detail_width {
    margin: 0 auto;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    pointer-events: none;
  }
  .detail_desc {
    /* margin-left: 20px !important; */
    margin-left: auto !important;
    margin-top: 20px !important;
  }
  .art_detail_title {
    font-size: 24px;
    margin-top: 0px;
    margin-left: 20px;
  }
}
/* md以上 */
@media screen and (min-width: 961px) {
  .detail_box {
    width: 400px;
    height: 400px;
  }
  .detail_alert_width {
    width: 400px;
    text-align: right;
  }
  .detail_square {
    width: 400px;
    height: 400px;
    object-fit: contain;
    pointer-events: none;
  }
  .detail_watermark1 {
    position: absolute;
    top: 10%;
    left: 10%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark2 {
    position: absolute;
    top: 20%;
    left: 20%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark3 {
    position: absolute;
    top: 30%;
    left: 30%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark4 {
    position: absolute;
    top: 40%;
    left: 40%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark5 {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark6 {
    position: absolute;
    top: 60%;
    left: 60%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark7 {
    position: absolute;
    top: 70%;
    left: 70%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark8 {
    position: absolute;
    top: 80%;
    left: 80%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark9 {
    position: absolute;
    top: 90%;
    left: 90%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .detail_watermark10 {
    position: absolute;
    top: 0%;
    left: 0%;
    font-size: 25px;
    color: #fff;
    opacity: 0.5;
  }
  .dialog_detail_box {
    width: 600px;
    height: 600px;
  }
  .dialog_detail_square {
    width: 500px;
    height: 500px;
    object-fit: contain;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    pointer-events: none;
  }
  .dialog_detail_height {
    margin: 0 auto;
    width: auto;
    height: 95%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    pointer-events: none;
  }
  .dialog_detail_width {
    margin: 0 auto;
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    pointer-events: none;
  }
  .detail_height {
    margin: 0 auto;
    height: 100%;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    pointer-events: none;
  }

  .detail_width {
    /* margin: 0 auto; */
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    pointer-events: none;
  }
  .detail_desc {
    margin-left: 50px !important;
  }
  .art_detail_title {
    font-size: 26px;
    margin-top: 0px;
    margin-left: 20px;
  }
}

.art_detail_button {
  position: relative;
  padding: 0 !important;
  z-index: 1;
}
.art_detail_price {
  position: absolute;
  border-radius: 4px;
  background-color: #808080;
  color: #fff;
  z-index: 2;
  padding: 0 5px;
}
.art_detail_price p {
  font-size: 14px;
}

.like_button {
  /* background-color: rgb(231, 76, 60); */
  background-color: #fff;
  color: rgb(231, 76, 60);
  padding: 0.1rem;
  border-radius: 0.4rem;
  border: 1px solid;
  border-color: #808080;
  position: absolute;
  z-index: 2;
  display: flex;
  margin: 0 auto;
}
.star_button {
  z-index: 2;
  position: absolute !important;
  padding: 0 !important;
  width: 20px;
  height: 20px;
  border-radius: 50% !important;
  color: #fff;
}
@media screen and (max-width: 600px) {
  .like_button {
    width: 48px;
    top: -10%;
    right: -171%;
  }
  .star_button {
    bottom: 5%;
    right: -139%;
  }
  .art_detail_price {
    bottom: 3%;
  }
}

@media screen and (min-width: 601px) and (max-width: 960px) {
  .like_button {
    width: 48px;
    top: -7%;
    right: -17%;
  }
  .star_button {
    bottom: 5%;
    right: 1%;
  }
  .art_detail_price {
    bottom: 5%;
    left: 3%;
  }
}

@media screen and (min-width: 961px) {
  .like_button {
    width: 50px;
    top: -7%;
    right: -372%;
  }
  .star_button {
    bottom: 5%;
    right: -317%;
  }
  .art_detail_price {
    bottom: 3%;
    left: 5%;
  }
}
.like_string {
  /* padding-left: 15px; */
  margin-left: 5px;
}

/* request */

.card_media {
  margin: 10px;
}

@media screen and (max-width: 600px) {
  .card_img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  .card_media {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
}

.request_card_size {
  margin: 10px 5px !important;
  width: 340px;
  height: 170px;
  background-color: black;
  display: inline-block;
  border-radius: 5px;
}
.request_card_img_size {
  width: 340px;
  height: 170px;
  object-fit: cover;
  border-radius: 5px;
  opacity: 0.7;
}
.request_card_title {
  width: 340px;
  top: 30%;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.request_card_alert {
  width: 340px;
  top: 10%;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

@media screen and (min-width: 601px) and (max-width: 960px) {
  .request_card_size {
    margin: 10px !important;
    width: 350px;
    height: 175px;
    background-color: black;
    display: inline-block;
    border-radius: 5px;
  }
  .request_card_img_size {
    width: 350px;
    height: 175px;
    object-fit: cover;
    border-radius: 5px;
    opacity: 0.7;
  }
  .request_card_title {
    width: 350px;
    top: 30%;
    text-align: center;
    font-size: 18px;
  }
  .request_card_alert {
    width: 350px;
    top: 10%;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }
}

@media screen and (min-width: 961px) {
  .request_card_size {
    margin: 10px !important;
    width: 370px;
    height: 185px;
    background-color: black;
    display: inline-block;
    border-radius: 5px;
  }
  .request_card_img_size {
    width: 370px;
    height: 185px;
    object-fit: cover;
    border-radius: 5px;
    opacity: 0.7;
  }
  .request_card_title {
    width: 370px;
    top: 30%;
    text-align: center;
    font-size: 18px;
  }
  .request_card_alert {
    width: 370px;
    top: 10%;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }
  .card_img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
  .card_media {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
}

/* request detail */

.request_box {
  margin: auto;
  /* background-color: #f0f0f0; */
  position: relative;
}

.request_square {
  margin: 0 auto;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .request_width {
    margin: 0 auto;
    width: 100%;
  }
  .request_height {
    margin: 0 auto;
    width: 90%;
    height: 100%;
  }
  .request_icon {
    width: 60px;
    height: 60px;
  }
}

@media screen and (min-width: 601px) and (max-width: 960px) {
  .request_box {
    width: 300px;
    height: 300px;
  }
  .request_width {
    margin: 0 auto;
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  .request_height {
    margin: 0 auto;
    width: auto;
    height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
  .request_icon {
    width: 60px;
    height: 60px;
  }
}

@media screen and (min-width: 961px) {
  .request_box {
    width: 400px;
    height: 400px;
  }

  .request_width {
    /* margin: 0 auto; */
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
  .request_height {
    margin: 0 auto;
    width: auto;
    height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
  .request_icon {
    width: 100px;
    height: 100px;
  }
}

.menu_title {
  font-weight: bold;
  padding-top: 32px;
  text-align: left;
  font-size: 18px;
  /* margin-left: 20px; */
}

.art_show_root {
  z-index: 0;
}
.art_show_img {
  object-fit: "cover";
  /* pointer-events: none; */
}

@media screen and (max-width: 600px) {
  .art_show_root {
    margin: 15px 5px;
    width: calc(50% - 16px);
  }
  .art_show_img {
    width: 130px;
    height: 130px;
    /* pointer-events: none; */
    -webkit-touch-callout: none;
  }
}

@media screen and (min-width: 601px) and (max-width: 960px) {
  .art_show_root {
    margin: 25px 16px;
    width: calc(33.33333% - 32px);
  }

  .art_show_img {
    width: 180px;
    height: 180px;
    -webkit-touch-callout: none;
  }
}

@media screen and (min-width: 961px) {
  .art_show_root {
    margin: 25px 16px;
    width: calc(33.33333% - 32px);
  }

  .art_show_img {
    width: 220px;
    height: 220px;
    -webkit-touch-callout: none;
  }
}

.like {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 1px;
  cursor: pointer;
  width: 80px;
  margin: 0 auto 0 0;
}

.favorite_icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px solid;
  border-color: gold;
}

.notifi_influencer_img {
  margin: auto;
}
.notifi_influencer_megaphone {
  margin: 0px 0px auto 0px;
}
.notifi_influencer_headphone {
  margin: 0px 0px 0px auto;
}
.notifi_hightouch {
  margin: 0px 0px auto 0px;
}

@media screen and (max-width: 600px) {
  .notifi_influencer_img {
    margin-top: -50px;
    width: 250px;
    height: 90px;
  }
  .notifi_influencer_megaphone {
    width: 100px;
    height: 100px;
  }
  .notifi_influencer_headphone {
    width: 100px;
    height: 100px;
  }
  .notifi_influencer_icon {
    margin-right: 20px;
    width: 150px;
    height: 150px;
  }
  .notifi_hightouch {
    margin-left: 20px;
    width: 90px;
    height: 180px;
  }
  .touch_string {
    margin-top: 10px;
    padding-left: 20px;
  }
}

@media screen and (min-width: 601px) {
  .notifi_influencer_img {
    margin-top: -50px;

    width: 400px;
    height: 120px;
  }
  .notifi_influencer_megaphone {
    width: 150px;
    height: 150px;
  }
  .notifi_influencer_headphone {
    width: 150px;
    height: 150px;
  }
  .notifi_influencer_icon {
    margin-right: 20px;
    margin-top: -50px;
    width: 200px;
    height: 200px;
  }
  .notifi_hightouch {
    margin-left: 20px;
    margin-top: -70px;
    width: 150px;
    height: 270px;
  }
  .touch_string {
    padding-left: 50px;
  }
}

/* home logo */
.illustLogo {
  margin-top: 15px;
  /* margin-left: 20px; */
  width: 360px;
  height: 70px;
}

@media screen and (max-width: 600px) {
  .request_card_text {
    font-size: 13px;
  }
  .home_request_pottin {
    width: 150px;
    height: 180px;
  }
  .request_detail_icon {
    width: 100px;
    height: 100px;
  }
  .request_newpost {
    position: absolute;
    width: 10%;
    height: auto;
    margin: auto;
    top: 1%;
    right: 0.5%;
  }
}

@media screen and (min-width: 601px) {
  .request_card_text {
    font-size: 25px;
  }
  .home_request_pottin {
    margin-top: -50px;
    margin-bottom: -50px;
    width: 180px;
    height: 250px;
  }
  .request_detail_icon {
    width: 150px;
    height: 150px;
  }
  .request_newpost {
    position: absolute;
    top: 1%;
    right: 0.5%;
    width: 10%;
    height: auto;
    margin: auto;
  }
}

/* mypage */

.mypage_username {
  font-weight: bold;
}

.notifi_request_img {
  /* margin: auto; */
  /* background-color: #f0f0f0; */
  position: relative;
  object-fit: contain;
}
@media screen and (max-width: 600px) {
  .mypage_username {
    font-size: 18px;
    text-align: left;
    margin-left: 20px;
  }
  .mypage_icon {
    width: 80px;
    height: 80px;
    margin: auto;
    object-fit: cover;
  }
  .mypage_avatar {
    width: 80px !important;
    height: 80px !important;
    margin: auto;
  }
  .notifi_request_img {
    width: 130px;
    height: 130px;
  }

  .mypage_desc {
    font-size: 12px;
    margin-left: 20px;
    margin-bottom: 0px !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 960px) {
  .mypage_username {
    font-size: 25px;
    text-align: left;
    margin-left: 20px;
  }
  .mypage_icon {
    width: 120px;
    height: 120px;
    margin: auto;
    object-fit: cover;
  }
  .mypage_avatar {
    width: 120px !important;
    height: 120px !important;
    margin: auto 50px auto 0;
  }
  .notifi_request_img {
    width: 160px;
    height: 160px;
  }
  .mypage_desc {
    margin-left: 20px;
    margin-bottom: 0px !important;
  }
}

@media screen and (min-width: 961px) {
  .mypage_username {
    font-size: 30px;
    text-align: left;
  }
  .mypage_icon {
    width: 180px;
    height: 180px;
    margin: auto;
    object-fit: cover;
  }
  .mypage_avatar {
    width: 180px !important;
    height: 180px !important;
    margin: auto 50px auto 0;
  }
  .notifi_request_img {
    width: 200px;
    height: 200px;
  }
  .mypage_desc {
    /* margin-left: 220px; */
    margin-bottom: 0px !important;
  }
}

/* home */

.home_grid_second_component {
  /* width: 35%; */
  display: flex;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 12px;
}

.home_grid_third_component {
  /* width: 40%; */
  display: flex;
  margin: auto;
}

.home_grid_radius {
  border-radius: 50%;
  background-color: aquamarine;
  margin: auto 30px;
}

.home_grid_five_component {
  margin: 0 auto;
  padding-top: 5px;
  text-align: center;
}
@media screen and (max-width: 600px) {
  .home_main_desc {
    font-size: 6px;
    margin: 0 auto;
  }
  .home_grid_img {
    width: 90px;
    height: 90px;
    object-fit: contain;
  }
  .home_grid_second_img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin: 10px auto auto auto;
  }
  .home_grid_third_img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin: 10px auto auto auto;
  }

  .home_grid_title {
    font-size: 8px;
    margin-top: 0px;
  }

  .home_grid_second_title {
    font-size: 18px;
  }
  .home_grid_second_desc {
    font-size: 12px;
  }
  .home_grid_second_component {
    padding: 5px;
    /* width: 40%; */
    width: 90%;
    margin: 10px;
  }
  .home_grid_third_component {
    /* width: 50%; */
    width: 90%;
    margin: auto auto 20px auto;
  }
  .home_grid_third_component_a {
    width: 50%;
    margin: auto;
  }

  .home_grid_radius {
    width: 10px;
    height: 10px;
  }
  .home_grid_four_title {
    font-size: 12px !important;
  }
  .home_grid_four_desc {
    font-size: 8px !important;
  }
  .home_grid_five_component {
    width: 90%;
  }
  .home_button {
    font-size: 13px !important;
  }
  .home_logo {
    width: 80px;
  }

  .request_card_desc {
    font-size: 9px;
  }

  .mypage_noproduct {
    width: 100px;
    height: 100px;
  }
  .home_header {
    width: 160px;
    left: 8%;
    bottom: 19%;
    /* padding: 1px 3px !important; */
    border-radius: 8px !important;
    padding: 6px !important;
  }
  .home_header_button {
    font-size: 12px;
  }
  .home_header_email {
    left: 14%;
    bottom: 8%;
    font-size: 10px;
    margin: 0px;
  }
  .bird_icon_size {
    width: 15px !important;
    height: 15px !important;
  }
  .home_header_emailbutton {
    font-size: 7px !important;
  }

  .home_change_desc {
    font-size: 12px;
    text-align: center;
  }
}

@media screen and (min-width: 601px) and (max-width: 960px) {
  .home_main_desc {
    font-size: 11px;
    margin: auto;
  }
  .home_grid_img {
    width: 90px;
    height: 90px;
    object-fit: contain;
  }

  .home_grid_title {
    font-size: 15px;
  }

  .home_grid_second_img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin: 10px auto auto auto;
  }
  .home_grid_third_img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin: 10px auto auto auto;
  }

  .home_grid_second_title {
    font-size: 16px;
  }
  .home_grid_second_desc {
    font-size: 12px;
  }

  .home_grid_second_component {
    padding: 30px;
    width: 90%;
  }
  .home_grid_radius {
    width: 15px;
    height: 15px;
  }
  .home_grid_third_component {
    width: 80%;
    margin: auto !important;
  }
  .home_grud_third_component_a {
    width: 40%;
    margin: auto !important;
  }

  .home_grid_four_desc {
    font-size: 11px !important;
  }
  .home_grid_five_component {
    width: 70%;
  }
  .home_button {
    color: #808080;
    font-size: 14px;
    cursor: pointer;
  }
  .home_button:hover {
    color: black;
    font-weight: bold;
    /* font-size: 17px !important; */
  }
  .home_logo {
    width: 128px;
  }
  .request_card_desc {
    font-size: 12px;
  }
  .mypage_noproduct {
    width: 100px;
    height: 100px;
  }
  .home_header {
    left: 14%;
    bottom: 20%;
    padding: 5px 10px !important;
  }
  .home_header_button {
    font-size: 12px !important;
  }

  .home_header_email {
    left: 15%;
    bottom: 5.5%;
    font-size: 10px;
  }
  .home_header_emailbutton {
    font-size: 9px !important;
  }
  .bird_icon_size {
    width: 18px !important;
    height: 18px !important;
  }

  .home_change_desc {
    font-size: 12px;
    width: 50%;
    text-align: right;
    margin-right: 20px;
  }

  .home_change {
    display: flex;
  }
}

@media screen and (min-width: 961px) {
  .home_main_desc {
    font-size: 13px;
    margin: auto;
  }
  .home_grid_img {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  .home_grid_title {
    font-size: 17px;
  }

  .home_grid_second_img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin: 10px auto auto auto;
  }
  .home_grid_third_img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin: 10px auto auto auto;
  }

  .home_grid_second_title {
    font-size: 18px;
    margin-left: 10px;
  }
  .home_grid_second_desc {
    font-size: 14px;
    margin-left: 10px;
  }
  .home_grid_second_component {
    /* padding: 50px 50px 0px 50px; */
    /* padding: 30px; */
    width: 90%;
    margin: 10px;
    padding: 10px;
  }

  .home_grid_radius {
    width: 20px;
    height: 20px;
  }
  .home_grid_third_component {
    width: 80%;
    margin: auto !important;
  }

  .home_grid_avatar {
    width: 80px !important;
    height: 80px !important;
  }
  .home_grid_four_title {
    font-size: 22px !important;
  }
  .home_grid_four_desc {
    font-size: 15px !important;
  }
  .home_grid_five_component {
    width: 70%;
  }
  .home_button {
    color: #808080;
    font-size: 14px;
    cursor: pointer;
  }
  .home_button:hover {
    color: black;
    font-weight: bold;
    /* font-size: 17px !important; */
  }
  .home_logo {
    width: 128px;
  }

  .request_card_desc {
    font-size: 12px;
  }

  .mypage_noproduct {
    width: 150px;
    height: 150px;
  }

  .home_header {
    left: 10%;
    bottom: 14%;
    /* padding: 5px 10px !important; */
    width: 256px;
    height: 48px;
  }
  .home_header_button {
    font-size: 18px;
  }

  .home_header_email {
    left: 16.5%;
    bottom: 1.5%;
    font-size: 12px;
  }
  .bird_icon_size {
    margin-right: 20px;
  }
  .home_header_emailbutton {
    font-size: 13px !important;
  }

  .home_change_desc {
    font-size: 14px;
    width: 50%;
    text-align: right;
    margin-right: 20px;
  }
  .home_change {
    display: flex;
  }
}

/* page edit  */

.edit_button {
  margin: auto 0 auto auto;
}

@media screen and (max-width: 600px) {
  .edit_button {
    margin: auto 0 auto auto;
  }

  .edit_button_size {
    /* padding: 0 !important; */
    font-size: 8px !important;
  }
  .edit_user_link {
    margin-left: 20px !important;
  }
  .edit_user_link_text {
    font-size: 8px;
  }
  .select_request_page {
    font-weight: bold;
    font-size: 18px;
    border-bottom: 3px solid #ff7c21;
    margin: 0 auto 0 auto;
    cursor: pointer;
  }
  .select_collecter_page {
    font-weight: bold;
    font-size: 18px;
    margin: 0 auto 0 auto;
    cursor: pointer;
  }
}

@media screen and (min-width: 601px) and (max-width: 961px) {
  .edit_button {
    margin: auto 0 auto 10px;
  }
  .edit_user_link {
    display: flex;
    margin-left: 20px;
  }
  .edit_twitter_button {
    margin-left: 10px;
  }

  .edit_user_link_text {
    font-size: 12px;
  }
  .select_request_page {
    font-weight: bold;
    font-size: 20px;
    border-bottom: 3px solid #ff7c21;
    margin: 0 auto 0 auto;
    cursor: pointer;
  }
  .select_collecter_page {
    font-weight: bold;
    font-size: 20px;
    margin: 0 auto 0 auto;
    cursor: pointer;
  }
}

@media screen and (min-width: 961px) {
  .edit_button {
    margin: auto 0 auto 10px;
  }
  .edit_user_link {
    display: flex;
    /* margin-left: 220px; */
  }
  .edit_twitter_button {
    margin-left: 10px;
  }

  .edit_user_link_text {
    font-size: 14px;
  }
  .select_request_page {
    font-weight: bold;
    font-size: 20px;
    border-bottom: 3px solid #ff7c21;
    margin: 0 auto 0 auto;
    cursor: pointer;
  }
  .select_collecter_page {
    font-weight: bold;
    font-size: 20px;
    margin: 0 auto 0 auto;
    cursor: pointer;
  }
}

/* payment_bank */
.edit_address_state {
  margin-top: -10px;
}
.edit_address_placeholder {
  font-size: 12px;
}
.edit_address_city {
  margin-left: 5px;
}
@media screen and (max-width: 600px) {
  .edit_address_placeholder {
    font-size: 8px;
  }
  .edit_address_state {
    text-align: left;
  }
  .edit_address_city {
    margin-left: 0px;
  }
}

@media screen and (min-width: 601px) {
  .edit_address_state {
    display: flex;
  }
}

/* imageArea */

.img_upload_form {
  border-radius: 5px !important;
  border: solid !important;
}

@media screen and (max-width: 600px) {
  .img_upload_form {
    width: 200px !important;
    height: 70px !important;
  }

  .img_upload_size {
    width: 60px !important;
    height: 60px !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 960px) {
  .img_upload_form {
    width: 400px !important;
    height: 150px !important;
  }
  .img_upload_size {
    width: 150px !important;
    height: 150px !important;
  }
}

@media screen and (min-width: 961px) {
  .img_upload_form {
    width: 500px !important;
    height: 180px !important;
  }
  .img_upload_size {
    width: 150px !important;
    height: 150px !important;
  }
}

@media screen and (max-width: 600px) {
  .notifi_receive {
    font-size: 13px;
  }
}

@media screen and (max-width: 600px) {
  .art_header {
    /* margin-top: 20px; */
    width: 95%;
    /* width: 320px; */
    height: 180px;
    background-color: black;
    display: inline-block;
    border-radius: 12px;
  }
  .art_list_header {
    /* width: 320px; */
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 12px;
    opacity: 0.7;
  }
  .art_list_title {
    width: 100%;
    top: 30%;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
  }
  .art_list_username {
    width: 100%;
    top: 70%;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
  }
  .art_list_edit {
    top: 0%;
    right: 0%;
  }
}

@media screen and (min-width: 601px) and (max-width: 960px) {
  .art_header {
    /* margin-top: 20px; */
    width: 700px;
    height: 234px;
    background-color: black;
    display: inline-block;
    border-radius: 12px;
  }
  .art_list_header {
    width: 700px;
    height: 234px;
    object-fit: cover;
    border-radius: 12px;
    opacity: 0.7;
  }
  .art_list_title {
    width: 700px;
    top: 30%;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }
  .art_list_username {
    width: 700px;
    top: 80%;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
  }
  .art_list_edit {
    top: 2%;
    right: 2%;
  }
}

@media screen and (min-width: 961px) {
  .art_header {
    /* margin-top: 5px; */
    width: 780px;
    height: 260px;
    background-color: black;
    display: inline-block;
    border-radius: 12px;
    margin: auto;
  }
  .art_list_header {
    width: 780px;
    height: 260px;
    object-fit: cover;
    border-radius: 12px;
    opacity: 0.7;
  }
  .art_list_title {
    width: 780px;
    top: 30%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
  }
  .art_list_username {
    width: 780px;
    top: 80%;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
  }
  .art_list_edit {
    top: 2%;
    right: 2%;
  }
}

@media screen and (max-width: 600px) {
  .art_request_list_high {
    width: 150px;
    height: 240px;
  }
  .art_request_list_notion {
    width: 200px;
    height: 200px;
  }
}

@media screen and (min-width: 601px) {
  .art_request_list_high {
    width: 200px;
    height: 320px;
  }
  .art_request_list_notion {
    width: 250px;
    height: 250px;
  }
}
.no_text::before {
  border: none !important;
}

.no_text::after {
  border: none !important;
}

.menuTitle {
  font-weight: bold;
  padding-top: 32px;
  text-align: left;
}

.title_required {
  color: red;
  font-size: 14px;
  font-weight: bold;
  padding: 4px;
  margin-left: 5px;
}

@media screen and (max-width: 600px) {
  .menuTitle {
    font-size: 20px;
    margin-left: 15px;
  }
}

@media screen and (min-width: 601px) {
  .menuTitle {
    font-size: 26px;
    margin-left: 30px;
  }
}

@media screen and (max-width: 600px) {
  .titlearound {
    width: 330px !important;
    margin: 10px !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 960px) {
  .titlearound {
    width: 700px !important;
    margin: 5px !important;
  }
}

@media screen and (min-width: 961px) {
  .titlearound {
    margin: 5px !important;
  }
}

.primary_button {
  background-color: #ff7c21 !important;
  color: #fff !important;
  font-size: 16px !important;
  height: 48px !important;
  margin-bottom: 16px !important;
  margin-top: 16px !important;
  width: 256px !important;
  border-radius: 12px !important;
}

.text_field {
  background-color: "#f0f0f0";
  margin-bottom: 16px !important;
}
@media screen and (max-width: 600px) {
  .request_title_field {
    width: 90%;
  }
  .select_favorite_request_page {
    font-weight: bold;
    font-size: 13px;
    border-bottom: 2px solid #ff7c21;
    margin: 0 auto 0 auto;
    cursor: pointer;
    color: #ff7c21;
    width: 50%;
  }
  .select_favorite_collecter_page {
    font-weight: bold;
    font-size: 13px;
    margin: 0 auto 0 auto;
    cursor: pointer;
    width: 50%;
  }
}
@media screen and (min-width: 601px) {
  .request_title_field {
    width: 99%;
  }
  .select_favorite_request_page {
    font-weight: bold;
    font-size: 15px;
    border-bottom: 3px solid #ff7c21;
    margin: 0 auto 0 auto;
    cursor: pointer;
    color: #ff7c21;
  }
  .select_favorite_collecter_page {
    font-weight: bold;
    font-size: 15px;
    margin: 0 auto 0 auto;
    cursor: pointer;
  }
}

.balloon1-left {
  position: relative;
  display: inline-block;
  margin: 1.5em 0 1.5em 15px;
  padding: 7px 10px;
  min-width: 120px;
  max-width: 100%;
  color: #555;
  font-size: 16px;
  background: #fef8f5;
  border: solid #e4e8ed;
}

.balloon1-left:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -30px;
  margin-top: -15px;
  border: 15px solid transparent;
  border-right: 15px solid #e4e8ed;
}
.balloon1-left:after {
  content: "";
  position: absolute;
  top: 50%;
  left: -28px;
  margin-top: -15px;
  border: 15px solid transparent;
  border-right: 15px solid #fef8f5;
}

.balloon1-left p {
  margin: 0;
  padding: 0;
}
